import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import { AppPropsWithLayout } from '@models/common';
import { appWithTranslation, useTranslation } from 'next-i18next';

import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';
import '../styles/app.scss';
import { siteUrl } from '@common/constant';

const AppLayout = dynamic(() => import('../components/Layout/index'));

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { isMainLayout } = pageProps;

  const getLayout = Component.getLayout ?? ((page) => <AppLayout isMainLayout={isMainLayout}>{page}</AppLayout>);
  const { asPath, locale } = useRouter();
  const { t } = useTranslation();

  const isTestEnvironment = process.env.NEXT_PUBLIC_ENV !== 'production';

  const [isLoading, setIsLoading] = useState(false);

  const previewImage = `${siteUrl}/images/preview/home-v2.png`;

  const canonicalUrl = (siteUrl + (locale === 'ja' ? '/ja' : '') + (asPath === '/' ? '' : asPath))
    ?.split('?')[0]
    ?.split('#')[0];

  const alternateUrls = [
    {
      language: 'en',
      url: (siteUrl + (asPath === '/' ? '' : asPath))?.split('?')[0],
    },
    {
      language: 'ja-JP',
      url: (siteUrl + '/ja' + (asPath === '/' ? '' : asPath))?.split('?')[0],
    },
  ];

  const handleLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener('load', handleLoading);

    return () => {
      window.removeEventListener('load', handleLoading);
    };
  }, []);

  return isLoading ? (
    <div className="fullpage-loading">
      <img src="/images/loading.gif" className="icon" alt="Loading" />
    </div>
  ) : (
    getLayout(
      <>
        <Head>
          <title>{t('common.appTitleV2')}</title>
          <meta name="description" content={t('common.appDescription')} key="description" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          {alternateUrls.map((item, index) => (
            <link rel="alternate" hrefLang={item.language} href={`${item.url}/`} key={index} />
          ))}
          {!isTestEnvironment && (
            <meta
              name="robots"
              content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
            />
          )}
          {isTestEnvironment && (
            <>
              <meta name="robots" content="noindex" />
              <meta name="googlebot" content="noindex" />
            </>
          )}
          <meta property="og:locale" content="en_GB" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={t('common.appTitleV2')} key="og_title" />
          <meta property="og:description" content={t('common.appDescription')} key="og_description" />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:site_name" content="Ekotek" />
          <meta property="og:image" content={previewImage} key="og:image" />
          <meta property="og:image:secure_url" content={previewImage} key="og:image:secure_url" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
          <meta property="og:image:alt" content="blockchain" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={t('common.appTitleV2')} key="twitter_title" />
          <meta name="twitter:description" content={t('common.appDescription')} />
          <meta name="twitter:image" content={previewImage} key="twitter:image" />
        </Head>
        <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=UA-142999586-1" />
        <Script strategy="afterInteractive">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TBFVFQP');
          `}
        </Script>
        <Script strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-142999586-1', {
            page_path: window.location.pathname,
            });
          `}
        </Script>
        <Component {...pageProps} />
      </>,
    )
  );
}

export default appWithTranslation(MyApp);
