const siteUrl = process.env.NEXT_PUBLIC_SITE_URL;
const careersSiteUrl = process.env.NEXT_PUBLIC_CAREERS_SITE_URL;

const SERVICES = {
  BLOCKCHAIN: 'blockchain',
  INDUSTRIES: 'industries',
  INDUSTRY_ECOMMERCE: 'industry-ecommerce',
  AI: 'artificial-intelligence',
  MOBILE_DEVELOPMENT: 'mobile-development',
  WEB_DEVELOPMENT: 'web-development',
  GAME_FI: 'gamefi',
  LEGACY_MIGRATION: 'legacy-migration',
  MEDIA_ENTERTAINMENT: 'media-entertainment',
  LOGISTICS: 'logistics',
};

const LIMIT_PORTFOLIOS = 5;

const STATUS_SURVEY = {
  START: 'start',
  SURVEY: 'survey',
  SUCCESS: 'success',
  IGNORE: 'ignore',
};

// get the category Ids by this API : https://cms.ekoios.vn/wp-json/wp/v2/service-category
const SERVICE_CATEGORY_ID = {
  en: {
    DEVELOPMENT_SERVICE: 674,
    EMERGING_TECHNOLOGY: 686,
    BLOCKCHAIN: 678,
    INDUSTRY: 740,
    ADVISORY: 756,
  },
  ja: {
    DEVELOPMENT_SERVICE: 676,
    BLOCKCHAIN: 680,
    EMERGING_TECHNOLOGY: 688,
    INDUSTRY: 742,
    ADVISORY: 756,
  },
};

export { siteUrl, careersSiteUrl, SERVICES, LIMIT_PORTFOLIOS, STATUS_SURVEY, SERVICE_CATEGORY_ID };
